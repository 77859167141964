// Text expander (EDU)
jQuery(function($){
	var $container = $('#topic-text-container');
	if ( ! $container.length ) return;
	$('body').on('click','.topic-text-container-toggle',function(e) 
	{
		e.preventDefault();
		var $this = $(this);
		$container.toggleClass( 'topic-text-container-condensed' );
		if ( $container.hasClass('topic-text-container-condensed') ) {
			$container.animate({scrollTop: 0}, 250);
		} else {
			$container.animate({scrollTop: '+=80px'}, 300);
		}
		var txtExpanded = $this.data('expanded-text');
		var txtCollapsed = $this.data('collapsed-text');

		if ( txtExpanded && txtCollapsed ) {
			if ( $container.hasClass('topic-text-container-condensed') ) {
				$this.text( txtCollapsed );
			} else {
				$this.text( txtExpanded );
			}
		}
	});
});

// Topic list expand collapse subtopics
jQuery(function($){
	var $listToggle = $('.ilts-edu_topic-list .expand-subcat-item-btn');
	if ( ! $listToggle.length ) return;
	$listToggle.on( 'click', function(e){
		e.preventDefault();
		// console.log('test');
		var $btn = $(this);
		var $list = $btn.parents('.children');
		if ( ! $list.length ) return;
		$list.find('.subcat-item.subcat-item-collapsed').removeClass('subcat-item-collapsed');
		$btn.remove();
	});
});

// Term list expand collapse terms
jQuery(function($){
	var $listToggle = $('.edu-topic-list .edu-topic-list-term-toggle');
	if ( ! $listToggle.length ) return;
	$listToggle.on( 'click', function(e){
		e.preventDefault();
		// console.log('test');
		var $btn = $(this);
		var $list = $btn.parents('.edu-topic-list');
		if ( ! $list.length ) return;
		$list.find('.edu-topic-list-term-hidden').removeClass('edu-topic-list-term-hidden');
		$btn.remove();
	});
});


jQuery(function($){
    $(document).on( 'contextmenu', '.no-contextual', function( e )
    {
       e.preventDefault();
       return false;
    }); 
}); 