jQuery(function ($) {
  // ajax_login object is localized only when user is not logged in
  if (typeof ajax_login === 'undefined') return;

  // Ajax Login
  $('form.fresh-ajax-login').on('submit', function (e) {
    e.preventDefault();

    var $this = $(this);
    var $btn = $this.find('.submit_button');
    var errorClass = 'fresh-login-err';

    if ($btn.hasClass('disabled')) return;
    $this.find('.' + errorClass).remove();

    var user_login = $this.find('input[name="username"]').val();
    var user_password = $this.find('input[name="password"]').val();
    var security = $this.find('input[name="_n"]').val();
    var remember = $this.find('input[name="remember"]:checked').length
      ? $this.find('input[name="remember"]:checked').val()
      : '';

    if (!user_login) {
      $this.prepend(
        '<div class="alert alert-danger ' +
          errorClass +
          '">' +
          ajax_login.i18n_username_required +
          '</div>'
      );
      return false;
    }
    if (!user_password) {
      $this.prepend(
        '<div class="alert alert-danger ' +
          errorClass +
          '">' +
          ajax_login.i18n_password_required +
          '</div>'
      );
      return false;
    }

    // Check for SSL/FORCE SSL LOGIN
    if (ajax_login.force_ssl_admin == 1 && ajax_login.is_ssl == 0) {
      console.error('Login disabled: SSL is required but not enforced');
      return true;
    }

    // Disable els before submit
    $btn.addClass('disabled');
    $this.find('.form-control').attr('readonly', true);
    // $this.find('input#remember').attr("disabled", true);

    // Ajax action
    $.ajax({
      url: ajax_login.ajaxurl,
      type: 'POST',
      dataType: 'json',
      data: {
        action: 'ajaxlogin',
        _n: security,
        user_login: user_login,
        user_password: user_password,
        remember: remember,
      },
    })
      .done(function (response) {
        if (response.success == 1) {
          window.location = ajax_login.redirect_to;
        } else {
          $btn.removeClass('disabled');
          $this.find('.form-control').attr('readonly', false);
          $this.prepend(
            '<div class="alert alert-danger ' + errorClass + '">' + response.error + '</div>'
          );
        }
      })
      .fail(function () {
        $btn.removeClass('disabled');
        $this.find('.form-control').attr('readonly', false);
        $this.prepend(
          '<div class="alert alert-danger ' +
            errorClass +
            '">An error occurred, please try again.</div>'
        );
      });
  });
});
