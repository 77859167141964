import hcSticky from 'hc-sticky';
import 'lazysizes';
import 'lazysizes/plugins/blur-up/ls.blur-up';

lazySizes.cfg.blurupMode = 'auto';

// import { throttle } from 'throttle-debounce';

jQuery(function ($) {
  $('img.scale-to-parent').imageScale({
    // fadeInDuration: 700,
    rescaleOnResize: true,
  });
});

// Sticky elements
jQuery(function ($) {
  var $subnav = $('#subnav');
  var $sidebar = $('#sidebar-content');
  var $breadcrumb = $('#page-breadcrumb');
  var $adminBar = $('#wpadminbar');

  var subnavHeight = $subnav.length ? $subnav.outerHeight(true) : 0;
  var adminBarHeight = $adminBar.length ? 32 : 0;
  var adminBarHeightMobile = $adminBar.length ? 46 : 0;

  if ($subnav.length) {
    $subnav.hcSticky({
      stickTo: 'body',
      stickyClass: 'is_stuck',
      top: adminBarHeight,
      // Responsive rules for variable admin bar height:
      responsive: {
        600: { top: 0 },
        782: { top: adminBarHeightMobile },
      },
    });
  }

  if ($breadcrumb.length) {
    $breadcrumb.hcSticky({
      stickTo: $sidebar.length ? '.col-inner' : '.body-pad',
      stickyClass: 'is_stuck',
      top: adminBarHeight + subnavHeight - 1, // Additional negative 1px to deal with the small gap that can appear when our navbar has a non abs height e.g. 46.5px.
      // Responsive rules for variable admin bar height:
      responsive: {
        600: { top: subnavHeight - 1 },
        782: { top: adminBarHeightMobile + subnavHeight - 1 },
      },
    });
  }

  if ($sidebar.length) {
    $sidebar.hcSticky({
      stickTo: '#sidebar-col-inner',
      stickyClass: 'is_stuck',
      top: subnavHeight + adminBarHeight + 20, // ( 20 gives us some additional padding above the sidebar)
      // Disable on <960 screen width (when the sidebar breaks into detached mobile view):
      responsive: { 960: { disable: true } },
    });

    // Refresh hcSticky sidebar whenever the main content column height changes
    // by creating a ResizeObserver instance (https://stackoverflow.com/a/14901150)
    // console.log('ResizeObserver in window?',('ResizeObserver' in window === true));
    if ('ResizeObserver' in window === true) {
      var resizeObserver = new ResizeObserver(function (entries) {
        // console.log('refresh sidebar sticky', entries[0].target.clientHeight);
        $sidebar.hcSticky('refresh');
      });
      resizeObserver.observe(document.getElementById('main-content-col'));
    }
  }
});

// Mobile Navigation
jQuery(function ($) {
  var $nav = $('#subnav #menu-primary-menu');
  $nav.slicknav({
    appendTo: '#subnav',
    // appendTo: 'body',
    allowParentLinks: false,
    closeOnClick: true,
    duration: 150,
  });
  $('body').on('click', 'a#hamburger', function (e) {
    e.preventDefault();
    $(this).toggleClass('opened');
    $nav.slicknav('toggle');
  });
});

// Account Dropdown
jQuery(function ($) {
  // Nav menu dropdowns
  $('body').on('click', '#subnav ul.menu li.menu-item-has-children > a', function (e) {
    e.preventDefault();
    var $li = $(this).parent('li');
    var isActive = $li.hasClass('menu-item-toggle-children');
    $('#subnav ul.menu li.menu-item-has-children').removeClass('menu-item-toggle-children');
    if (isActive) {
      $li.removeClass('menu-item-toggle-children');
    } else {
      $li.toggleClass('menu-item-toggle-children');
    }
  });
  $(document).on('mouseup', function (e) {
    var container = $('#subnav ul.menu li.menu-item-has-children.menu-item-toggle-children');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass('menu-item-toggle-children');
      container.off('click', document);
    }
  });

  // The account dropdown
  $('body').on('click', 'a#my-account-link', function (e) {
    e.preventDefault();
    var $this = $(this);

    $this.toggleClass('open');
    if ($('#mobile-menu-link').hasClass('open')) {
      $('#mobile-menu-link').click();
      $('#account-dropdown input#user_login').blur();
    }
    $('#account-dropdown').toggle();
    $('#account-dropdown input#user_login').focus();
  });

  $(document).on('mouseup', function (e) {
    var container = $('#account-dropdown');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.hide();
      $('#my-account-link').removeClass('open');
      container.off('click', document);
    }
  });
});

// Join / Register Highlight Chosen
jQuery(function ($) {
  // Highlight sub levels
  var $sub_levels = $('ul#rcp_subscription_levels');
  if (!$sub_levels.length) return;

  highLightChoice();
  $sub_levels.find('input.rcp_level').on('change', function () {
    highLightChoice();
  });
  function highLightChoice() {
    $('.join_current_sub_choice').removeClass('join_current_sub_choice');
    $sub_levels
      .find('input.rcp_level:checked')
      .parent('li.rcp_subscription_level')
      .addClass('join_current_sub_choice');
  }
});

// BS Tooltips
jQuery(function ($) {
  $('body').tooltip({
    selector: '*[data-toggle="tooltip"]',
    container: 'body',
  });

  $('body').on('click', '*[data-toggle="tooltip"]', function () {
    var isShowing = $(this).data('isShowing');
    $('*[data-toggle="tooltip"]').tooltip('hide');
  });
});

// Search
jQuery(function ($) {
  $('body').on('click', '#searchform .search-btn', function (e) {
    e.preventDefault();
    $(this).parents('form#searchform').submit();
  });

  $('body').on('keyup', 'input#s', function () {
    if (!$(this).val()) {
      $(this).removeClass('focused');
    }
  });

  // Mobile search:
  $('a#search-container').click(function (e) {
    e.preventDefault();
    var $input = $('#search-dropdown input');
    if (!$input.is(':focus')) $(this).toggleClass('opened');
    $input.focus();
  });

  $('body').on('focus', '#navbar-search-container #searchform input', function (e) {
    $(this).parents('#navbar-search-container').addClass('search-has-focus');
  });
  $('body').on('blur', '#navbar-search-container #searchform input', function (e) {
    $(this).parents('#navbar-search-container').removeClass('search-has-focus');
  });
});

// BS Collapse defaults
jQuery(function ($) {
  $('a[data-toggle="collapse"]').on('click', function (e) {
    e.preventDefault();
  });
});

// GRID AND LIST VIEW
jQuery(function ($) {
  // GRID VIEW HOVER
  $(document).on('mouseenter', 'ul.grid-view > li .grid-thumb-container', function () {
    $(this).parents('li').addClass('grid-view-hover');
  });
  $(document).on('mouseleave', 'ul.grid-view > li .grid-thumb-container', function () {
    $(this).parents('li').removeClass('grid-view-hover');
  });

  // LIST VIEW HOVER
  $(document).on(
    'mouseenter',
    'ul.list-view > li .thumb-container, ul.list-view > li .excerpt',
    function () {
      $(this).parents('li').addClass('list-view-hover');
    }
  );
  $(document).on(
    'mouseleave',
    'ul.list-view > li .thumb-container, ul.list-view > li .excerpt',
    function () {
      $(this).parents('li').removeClass('list-view-hover');
    }
  );
});

// Modal Live Load
// Load a div from another page into a bootstrap modal using element data tags
jQuery(function ($) {
  var modalId = 'js-live-view-modal';
  var xhr = null;

  $('body').on('click', 'a[data-modal-live-load]', function (e) {
    e.preventDefault();
    if (xhr) xhr.abort();

    var href = $(this).prop('href');

    // Prevent live loading external links
    if (isExternal(href)) return;

    appendLiveLoadModal();
    var $modal = $('#' + modalId);
    var $modalContent = $modal.find('.modal-body');
    var $permalink = $modal.find('a.permalink-href');

    $modalContent.empty();
    $permalink.attr('href', '#').hide();
    $modalContent.addClass('fu-reloading');

    var content = $(this).data('modal-live-load');
    var modalSize = $(this).data('modal-live-load-size');
    var modalSizeClass =
      modalSize && modalSize == 'sm'
        ? 'modal-sm'
        : modalSize && modalSize == 'lg'
        ? 'modal-lg'
        : '';
    if (href == '#' || !href || !content) return;

    xhr = jQuery.ajax({
      url: href,
      dataType: 'html',
      success: function (html) {
        var accessNotice = jQuery('.ilts-rcp-access-denied-container', jQuery(html));
        if (accessNotice.length) {
          $modalContent.html(accessNotice);
        } else {
          var div = jQuery(content, jQuery(html));
          if (div.length) {
            $modalContent.html(div);
            $permalink.attr('href', href).show();
          } else {
            $modalContent.html(
              '<div style="padding: 45px 20px; color: #999;" class="text-center">Failed to load the content, please try again.</div>'
            );
          }
        }
        $modalContent.removeClass('fu-reloading');
      },
    });
    $modal.children('.modal-dialog').removeClass('modal-lg modal-sm').addClass(modalSizeClass);
    $modal.modal('show');
  });

  function appendLiveLoadModal() {
    var $modal = $('#' + modalId);
    if ($modal.length) return;
    var html = '';
    html +=
      '<div id="' +
      modalId +
      '" class="modal fade live-load-modal" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
    html += '<div class="modal-dialog">';
    html += '<div class="modal-content">';
    html += '<div class="modal-body text-style">';
    html += '</div>';
    html += '<div class="modal-footer">';
    html += '<a class="btn btn-default permalink-href" href="#">Permalink</a>';
    html += '<a class="btn btn-brand" data-dismiss="modal">Close</a>'; // close button
    html += '</div>'; // footer
    html += '</div>'; // content
    html += '</div>'; // dialog
    html += '</div>'; // modalWindow
    $('body').append(html);
  }

  var checkDomain = function (url) {
    if (url.indexOf('//') === 0) {
      url = location.protocol + url;
    }
    return url
      .toLowerCase()
      .replace(/([a-z])?:\/\//, '$1')
      .split('/')[0];
  };

  var isExternal = function (url) {
    return (
      (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
      checkDomain(location.href) !== checkDomain(url)
    );
  };
});
